var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "ag-grid-demo" } },
      [
        _c(
          "vx-card",
          {
            attrs: {
              title: _vm.$t(_vm.resources.List.i18n) || _vm.resources.List.name
            }
          },
          [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between items-center" },
              [
                _c(
                  "div",
                  {
                    staticClass: "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                  },
                  [
                    _c(
                      "vs-dropdown",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { "vs-trigger-click": "" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                          },
                          [
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.currentPage * _vm.paginationPageSize -
                                      (_vm.paginationPageSize - 1)
                                  ) +
                                  "\n                -\n                " +
                                  _vm._s(
                                    _vm.tracerList.length -
                                      _vm.currentPage * _vm.paginationPageSize >
                                      0
                                      ? _vm.currentPage * _vm.paginationPageSize
                                      : _vm.tracerList.length
                                  ) +
                                  "\n                of " +
                                  _vm._s(_vm.tracerList.length) +
                                  "\n              "
                              )
                            ]),
                            _c("feather-icon", {
                              attrs: {
                                icon: "ChevronDownIcon",
                                svgClasses: "h-4 w-4"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "vs-dropdown-menu",
                          [
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(20)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("20")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(50)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("50")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(
                                      100
                                    )
                                  }
                                }
                              },
                              [_c("span", [_vm._v("100")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(
                                      150
                                    )
                                  }
                                }
                              },
                              [_c("span", [_vm._v("150")])]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                  },
                  [
                    _c("vs-input", {
                      staticClass: "mb-4 md:mb-0 mr-4",
                      attrs: {
                        placeholder:
                          _vm.$t(_vm.resources.Search.i18n) ||
                          _vm.resources.Search.name
                      },
                      on: { input: _vm.updateSearchQuery },
                      model: {
                        value: _vm.searchQuery,
                        callback: function($$v) {
                          _vm.searchQuery = $$v
                        },
                        expression: "searchQuery"
                      }
                    }),
                    _c(
                      "vs-button",
                      {
                        staticClass: "mb-4 md:mb-0",
                        on: {
                          click: function($event) {
                            return _vm.gridApi.exportDataAsCsv()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(_vm.resources.ExportAs.i18n) ||
                                _vm.resources.ExportAs.name
                            ) +
                            "\n            CVS\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            ),
            _c("ag-grid-vue", {
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                gridOptions: _vm.gridOptions,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.tracerList,
                rowSelection: "single",
                colResizeDefault: "shift",
                animateRows: true,
                floatingFilter: true,
                pagination: true,
                paginationPageSize: _vm.paginationPageSize,
                suppressPaginationPanel: true
              },
              on: { "row-selected": _vm.onRowSelected }
            }),
            _c("vs-pagination", {
              attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full text-right" },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "rgb(174,174,174)", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/tracer/summary")
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Return.i18n) ||
                        _vm.resources.Return.name
                    )
                  )
                ]
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }